$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-dark-jungle: #1E1C1D;
$color-black-cat: #463D3C;
$color-light-brown: #BDAB8E;
$color-zues: #2A1D25;
$color-pale-carmine: #A83834;
$color-light-gray: #d6d4ce;
$color-romance: #FEFEFE;


@font-face {
  font-family: 'Starlit Drive';
  src: url('../font/StarlitDrive.woff2') format('woff2'),
    url('../font/StarlitDrive.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$primary-font: "poppins";
$secondary-font: "Starlit Drive";
$tertiary-font: "rama-slab-m";
$another-font: "garamond-premier-pro";