@import "variables";
@import "framework/framework";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $color-black;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
	width: 100%;
	overflow-x: hidden;
	position: relative;
}

a {
	color: $color-black;

	@include hover-focus {
		color: $color-white;
	}
}

.split {
	display: flex;
	flex-direction: column;

	.side {
		flex: 1;
	}

	@include tablet-up {
		flex-direction: row;
	}
}

.sr {
	display: none;
}

.social {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	gap: 10px;
}

.btn {
	display: inline-block;
	text-align: center;
	font-size: 12px;
	background-color: transparent;
	border: solid 1px transparent;
	padding: 6px 20px;
	font-family: $primary-font;
	text-transform: uppercase;
	transition: all .3s ease-in-out;

	@include tablet-up {
		font-size: 24px;
	}

	&.btn-red {
		background-color: $color-pale-carmine;
		color: $color-white;
		font-family: $tertiary-font;

		@include hover-focus {
			background-color: $color-white;
			color: $color-pale-carmine;
		}
	}

	&.btn-small {
		@include tablet-up {
			font-size: 12px;
		}
	}

	&.btn-western {
		background-color: $color-white;
		color: $color-black-cat;
		text-transform: uppercase;
		padding: 6px 40px;

		@include hover-focus {
			background-color: $color-white;
			color: $color-black-cat;
		}
	}

	&.btn-western-2 {
		background-color: $color-black-cat;
		color: $color-white;
		text-transform: uppercase;
		font-size: 55px;
		padding: 6px 70px;

		@include hover-focus {
			background-color: $color-black-cat;
			color: $color-black-cat;
		}
	}

	&.btn-white {
		font-family: $another-font;
		font-size: 1rem;
		background-color: $color-romance;
		color: $color-dark-jungle;
		transition: all .3s ease-in-out;
		padding-top: 8px;

		@include hover-focus {
			opacity: .6;
		}
	}

	&.btn-black {
		font-family: $primary-font;
		font-size: 1rem;
		background-color: $color-black-cat;
		color: $color-white;
		transition: all .3s ease-in-out;
		padding-top: 8px;

		@include hover-focus {
			opacity: .6;
		}
	}
}

.slick-slider {
	position: relative;
	padding: 0 30px;

	.slick-arrow {
		background-color: transparent;
		padding: 0;
		border: none;
		font-size: 25px;
		position: absolute;
		top: calc(50% - 15px);
		z-index: 99;

		&.slick-prev {
			left: 0;
		}

		&.slick-next {
			right: 0;
		}

		i {
			font-weight: 400;
		}
	}
}

.section-heading {
	font-family: $secondary-font;
	text-align: center;
	text-transform: capitalize;
	font-size: rems(84);
	margin: 0 0 rems(40) 0;
	font-weight: 400;

	@include tablet-up {
		font-size: rems(96);
	}
}

.mobile {
	@include tablet-up {
		display: none !important;
	}
}

.desktop {
	@include tablet-down {
		display: none !important;
	}
}

header {
	background-color: $color-black-cat;
	padding: 0;
	z-index: 9;

	@include tablet-up {
		background-color: $color-black-cat;
		padding: 20px 0;
	}

	.container {
		max-width: 100%;

		nav {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 20px;
			padding: 0 rems(20);

			@media screen and (max-width: 1240px) {
				flex-direction: column;
				align-items: center;
			}


			h1 {
				img {
					max-width: rems(350);
					filter: invert(1);
				}
			}

			ul.nav {
				list-style: none;
				padding: 0;
				margin: 0;
				display: flex;
				flex-direction: row;
				justify-content: center;
				gap: 20px;

				@include tablet-up {
					justify-content: flex-start;
					gap: 40px;
				}

				li {
					a {
						font-family: $primary-font;
						font-size: rems(22);
						color: $color-romance;
						text-transform: uppercase;
						letter-spacing: .15em;
						transition: all .3s ease-in-out;

						@include hover-focus {
							opacity: .6;
						}

						@include tablet-down {
							font-size: 16px;
						}
					}
				}
			}

			.links {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: rems(24);

				@include phone-down {
					flex-direction: column;
					gap: rems(10);
				}

				ul.social {

					@include phone-down {
						gap: rems(40);
						margin-bottom: rems(20);
					}

					a {
						transition: all .3s ease-in-out;

						@include hover-focus {
							opacity: .6;
						}

						i {
							color: $color-romance;
						}
					}
				}


				.btn {
					font-family: $primary-font;
					font-size: rems(20);
					padding-top: 8px;
				}
			}
		}
	}

	@include tablet-up {

		.container {
			.split {


				.side {
					.nav {}
				}
			}
		}
	}
}

.swiper {

	.swiper-button-next,
	.swiper-button-prev {
		&::before {
			content: '';
			display: block;
			width: 23px;
			height: 23px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-color: transparent;
		}

		&::after {
			display: none;
		}
	}

	.swiper-button-prev {
		&::before {
			background-image: url('../../dist/img/refresh/left.png');
		}
	}

	.swiper-button-next {
		&::before {
			background-image: url('../../dist/img/refresh/right.png');
		}
	}

	.swiper-pagination-bullet-active {
		background-color: $color-romance;
	}
}

main {

	#hero {
		background-color: $color-dark-jungle;

		.hero-wrapper {
			position: relative;
			width: 100%;
			height: auto;

			img.hero-desktop {
				@include phone-down {
					display: none;
				}
			}

			img.hero-mobile {
				@include tablet-up {
					display: none;
				}
			}

			.content-wrapper {
				position: absolute;
				inset: 0;
				width: 100%;

				@include phone-down {
					position: static;
				}

				.container {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					height: 100%;

					@include phone-down {
						justify-content: center;
					}

					.hero-content {
						display: flex;
						flex-direction: column;
						align-items: center;
						padding: rems(48) rems(48) rems(150);

						@include tablet-down {
							padding: rems(48) 0 rems(150);
						}


						p {
							color: $color-black-cat;
							font-family: $another-font;
							font-size: rems(28);
							text-transform: uppercase;
							letter-spacing: .2em;
							padding: 0;
							margin: 0;
						}

						p.title {
							font-family: $secondary-font;
							font-size: rems(172);
							text-transform: capitalize;
							letter-spacing: 0;
						}

						p.status {
							margin-bottom: rems(20);
						}

						.btn {
							font-size: rems(40);
							letter-spacing: .15em;
							padding: rems(20) rems(50);
						}
					}
				}
			}
		}
	}

	#music {
		background-color: $color-black-cat;
		padding: rems(64) 0;

		.container {
			.slider-music {
				.slick-arrow {
					color: gray;
				}

				.music-item {
					min-height: rems(450);

					@include tablet-down {
						padding-bottom: rems(40);
					}

					@include tablet-up {
						padding: 0 rems(40);
					}

					.split {
						gap: rems(40);

						.side {
							.cover {
								width: 100%;
							}

							h3 {
								font-family: $primary-font;
								font-size: rems(32);
								font-weight: 400;
								margin: 0;
								text-transform: uppercase;
								text-align: center;

								@include tablet-up {
									font-size: rems(75);
								}
							}

							.copy {
								width: 100%;
								height: auto;
								max-height: 220px;
								object-fit: contain;
								max-width: 500px;
							}

							h4 {
								font-family: $secondary-font;
								text-align: center;
								font-weight: 400;
								letter-spacing: 4px;
								font-size: 51px;
								text-transform: uppercase;
							}

							&:last-child {
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
							}

							.btn {
								margin-top: rems(38);
								font-size: rems(40);
							}
						}
					}
				}
			}
		}
	}

	#videos {
		padding: rems(64) 0;
		background-color: $color-light-brown;
		color: $color-white;

		.container {
			.video-featured {
				padding: 0;
				margin-bottom: 60px;

				iframe {
					width: 100%;
					height: 300px;

					@include tablet-up {
						height: 600px;
					}
				}



				.title {
					text-align: center;
					font-size: 18px;
					text-transform: uppercase;
					margin-top: 10px;
					font-weight: 400;
					letter-spacing: 2px;
					color: $color-white;
				}
			}

			.slider-video {

				@include tablet-up {
					padding: 0 40px;
				}

				img {
					width: 100%;
					height: 240px;
					object-fit: cover;
				}

				.slick-list {
					margin: 0 -30px;

					.slick-slide {
						margin: 0 30px;
					}
				}

				.video-item {
					padding-bottom: 40px;

					@include tablet-up {
						cursor: pointer;

						img {
							height: 300px;
						}
					}
				}

				.title {
					text-align: center;
					font-size: 14px;
					text-transform: uppercase;
					margin-top: 10px;
					font-weight: 400;
					letter-spacing: 2px;
				}

				.slick-arrow {
					color: $color-white;
				}
			}
		}
	}

	#tour {
		background-color: $color-black-cat;
		padding: 50px 0;
		position: relative;

		.bg-image {
			position: absolute;
			right: left;
			bottom: 0;
			max-width: 680px;
			max-height: 100%;
			pointer-events: none;

			@media screen and (max-width: 1100px) {
				max-width: 550px;
			}

			@include phone-down {
				right: 50%;
				transform: translateX(50%);
			}
		}

		.container {
			.wrapper-wrapper {
				position: relative;
				width: 50%;
				max-width: 650px;
				margin-left: auto;

				@include phone-down {
					width: 100%;
					max-width: 400px;
					margin: 0 auto;
				}

				.transparent-bg {
					@include tablet-down {
						background-image: url('../../dist/img/gradient-white.png');
					}

					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 3;
					opacity: 0.2;

					@include tablet-down {
						opacity: 0.3;
					}
				}

				.wrapper {
					@include tablet-down {
						padding: 16px 30px;
					}

					text-align: center;
					color: $color-white;
					overflow-y: scroll;
					height: 530px;
					z-index: 5;
					position: relative;
					// text-shadow: 0 0 5px $color-black;
					// hide scrollbar
					-ms-overflow-style: none;
					/* for Internet Explorer, Edge */
					scrollbar-width: none;

					/* for Firefox */
					&::-webkit-scrollbar {
						display: none;
						/* for Chrome, Safari, and Opera */
					}
				}
			}
		}

		.event {
			display: flex;
			margin-bottom: rems(20);
			font-size: 16px;
			font-family: $primary-font;

			@include tablet-up {
				text-align: left;
			}

			@include tablet-down {
				display: block;

				>div {
					margin-bottom: 5px;
				}
			}

			>div:first-child {
				flex: 2
			}

			>div {
				flex: 1;

				&.event-links {
					max-width: max-content;
				}
			}

			.event-date {
				font-weight: 700;

				.event-venue,
				.event-location {
					font-weight: 400;
				}
			}

			.btn {
				background-color: #9b4039;
				color: $color-romance;
				font-family: $another-font;

				// @include hover-focus {
				// 	background-color: silver;
				// }
			}
		}

		@include tablet-up {
			padding: 100px 0;
		}
	}

	#about {
		background-image: url('../../dist/img/bg-bio.jpg');
		background-position: left top;
		background-size: cover;
		padding: 100px 0;

		@include tablet-down {
			background-position: 70% top;
		}

		.container {
			display: flex;
			justify-content: flex-start;

			.wrapper {
				max-width: 650px;
				padding: 30px;
				color: $color-romance;
				background-color: transparent;

				@include tablet-down {
					background-color: #000000ad;
				}

				.copy {
					width: 100%;
					max-width: 400px;
					margin: 0 auto 30px;
				}

				.bio {
					display: grid;
					gap: 20px;

					p {
						font-size: 16px;
						line-height: 1.4;
					}
				}
			}
		}
	}

	#shop {
		background-color: $color-light-brown;
		padding: 50px 0;

		.container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		@include tablet-up {
			padding: 100px 0;
		}

		.wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 30px;
			margin-bottom: rems(48);
			width: 100%;

			.item {
				a {
					img {
						max-width: 500px;
						width: 100%;
						aspect-ratio: 3/4;
						object-fit: contain;
					}
				}
			}

			.btn {
				font-size: rems(28);
				padding: rems(12) rems(20) rems(8);
			}
		}
	}

}

footer {
	padding: 50px 0;
	background-color: $color-black-cat;
	color: $color-romance;

	#instagram {
		display: none;
	}

	#newsletter {
		#karley-scott-collins-email-signup {
			width: rems(700);
			max-width: 100%;
			margin: rems(20) auto;

			.thank-you {
				flex-basis: 100%;
				font-size: rems(22);
				text-align: center;
				width: 100%;
				display: block;
			}

			.wrap {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				>input,
				>label {
					flex-basis: 50%;
				}

				>label {
					margin: rems(15) 0;
				}

				@media screen and (max-width: 500px) {
					justify-content: center;

					>input,
					>label {
						flex-basis: 100%;
					}

					>label {
						margin: 0;
					}
				}
			}

			input {
				background: transparent;
				text-align: left;
				border: none;
				border-radius: 0;
				border-bottom: 2px solid $color-romance;
				margin-bottom: rems(10);
				padding: rems(5) rems(10);
				color: $color-romance;

				&::placeholder {
					color: $color-romance;
					text-transform: uppercase;
				}
			}

			label {
				font-size: rems(11);
				// color: blue;
				text-transform: uppercase;
				text-align: left;
			}

			button {
				margin: 0 auto;
				display: block;
				margin-top: rems(20);
				letter-spacing: .1em;
				font-size: rems(28);
				padding: rems(3) rems(20) 0;
				transition: all .3s ease-in-out;
				background-color: $color-romance;
				color: $color-black-cat;

				@include hover-focus {
					color: $color-black-cat;
					background-color: $color-romance;
				}
			}
		}

		.community {
			margin-top: rems(60);
			text-transform: uppercase;
			font-weight: bold;
			font-size: rems(17);
			letter-spacing: .05em;
			margin-bottom: 0;
			text-align: center;
			margin-bottom: 10px;

			a {
				color: $color-romance;
				text-decoration: underline;
			}
		}

		.note {
			text-align: center;
			font-size: rems(10);
			font-family: sans-serif;
			text-transform: uppercase;
			letter-spacing: .05em;
		}

		.social {
			gap: 30px;

			a {
				color: $color-romance;
				font-size: 30px;
			}

			margin: 30px 0;
		}
	}

	.copyright {
		font-size: 10px;
		text-align: center;
		line-height: 1.6;

		a {
			color: $color-romance;
		}
	}

	@include tablet-up {
		padding: 100px 0;
	}
}

#feed {
	display: flex;
	width: 100%;
	margin: 0 auto;
	flex-flow: row wrap;
	position: relative;
	z-index: 1;

	>div {
		width: 32%;
		padding: 1%;

		@media screen and (max-width: 800px) {
			flex-basis: 50%;

			&:nth-last-child(2),
			&:last-child {
				display: none;
			}
		}

		a {
			background-repeat: no-repeat;
			background-size: cover;
			display: block;
			overflow: hidden;
			padding-bottom: 100%;
			position: relative;
			width: 100%;

			span {
				align-items: center;
				background-color: rgba(0, 0, 0, .7);
				color: $color-romance;
				display: flex;
				font-weight: 300;
				justify-content: center;
				line-height: 1.3;
				padding: rems(15);
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				transform: translateX(-100%);
				transition: transform .3s;
			}

			&:active,
			&:focus,
			&:hover {
				span {
					transform: translateX(0);
				}
			}
		}
	}
}